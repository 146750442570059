<header>
    <mat-toolbar class="toolbar-menu">
        <!-- <div class="menuDesplegable">
            <button mat-button [matMenuTriggerFor]="menu">Modales</button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item *ngFor="let m of modals" (click)="openModalGeneric(m.c)">{{ m.nombre
                    }}</button>
            </mat-menu>
        </div> -->

        <button mat-icon-button (click)="toggleMenu()">
            <mat-icon style="color: #ffffff">menu</mat-icon>
        </button>

        <div class="imgLogo" style="cursor: pointer;" routerLink="/">
            <img src="assets/iconos/logo_figuacero_blanco.png" alt="">
        </div>

        <div class="ingresarDireccion" (click)="openModalCP()">
            <div class="iconoUbi">
                <mat-icon class="mr-2 iconoLoca">location_on</mat-icon>
            </div>

            <div class="txtUbi">
                <span *ngIf="!codigoPostal">Ingresar</span>
                <span>Código Postal</span>
                <span *ngIf="codigoPostal">{{ codigoPostal }}</span>
            </div>
        </div>

        <div class="barraBuscador">
            <div class="select-form">
                <select class="" [formControl]="controlCategory">
                    <option selected [value]="0">Todos</option>
                    <option [value]="item.iIdCategoria" *ngFor="let item of categoriesList">{{ item.sNombreCategoria }}
                    </option>
                </select>
            </div>
            <input [formControl]="controlSearch" (keyup.enter)="search()">
            <button mat-button style="color: #ffffff;" (click)="search()">Buscar</button>
        </div>


        <div class="toolbar-menu__center">
            <!-- menu user -->
            <span class="user-login">{{userName}}</span>
            <mat-icon class="toolbar-menu__img-icon iconoColor" [matMenuTriggerFor]="beforeMenu">
                perm_identity
            </mat-icon>

            <!-- float cart -->
            <div class="menuCart">
                <!-- EL QUE ESTABA ANTERIORMENTE -->
                <!-- <mat-icon class="toolbar-menu__img-icon iconoColor" [routerLink]="['/cart']"> -->
                <!-- EL ACTUAL, SIN EL ROUTER -->
                <mat-icon class="toolbar-menu__img-icon iconoColor">
                    shopping_cart
                </mat-icon>

                <div class="menuCart__content">
                    <div class="menuCart__box-cart" #floatCart>

                        <ng-container #emptyCart *ngIf="productosCarrito.length === 0">
                            <div class="text-center">
                                <img src="assets/iconos/cart-empty.svg" alt="empty-cart" style="width: 200px;">
                                <p class="text-primary">El carrito se encuentra vacío</p>
                            </div>
                        </ng-container>


                        <ng-container *ngIf="productosCarrito.length > 0">
                            <!-- item row -->
                            <div class="menuCart__item" *ngFor="let itemCart of productosCarrito">
                                <!-- item imagen -->
                                <div class="menuCart__image">
                                    <img class="menuCart__img" [src]="itemCart.sUrlImagen">
                                </div>

                                <!-- item info -->
                                <div class="menuCart__info">
                                    <div class="menuCart__title">
                                        <span>{{ itemCart.sNombre }}</span>
                                    </div>
                                    <div class="menuCart__description">
                                        <span class="menuCart__price">{{ itemCart.dprecioCI | currency }}</span>
                                        <span class="menuCart__quantity mr-3">x{{ itemCart.dCantidad }}</span>
                                    </div>
                                </div>

                                <!-- item delete -->
                                <div class="menuCart__delete">
                                    <mat-icon color="primary" (click)="deleteItem(itemCart)">add_circle</mat-icon>
                                </div>
                            </div>

                            <!-- actions -->
                            <div class="menuCart__actions">
                                <button mat-raised-button color="primary" (click)="goToCart()">Ver carrito</button>
                                <!-- <button mat-raised-button color="primary" routerLink="/checkout">Pagar ahora</button> -->
                            </div>
                        </ng-container>


                    </div>
                </div>

            </div>

            <!-- float actions user -->
            <mat-menu #beforeMenu="matMenu" xPosition="before" class="menu-usuario">
                <ng-container *ngIf="_userService.isLogged()">
                    <button mat-menu-item routerLink="/user/account">Mi cuenta</button>
                    <button mat-menu-item (click)="logout()">Cerrar sesión</button>
                </ng-container>

                <ng-container *ngIf="!_userService.isLogged()">
                    <button mat-menu-item (click)="openModal('signIn')">Iniciar sesión</button>
                    <button mat-menu-item (click)="openModal('signUp')">Registrarse</button>
                </ng-container>

            </mat-menu>
        </div>

        <div class="toolbar-menu__right">
            <a href="https://www.facebook.com/Figueacero" target="_blank">
                <div class="icono iconos_svg">
                    <!-- <img src="assets/iconos/facebook.png" alt="facebook"> -->
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" style="width: 11px;">
                        <path
                            d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
                    </svg>
                </div>
            </a>
            <a href="https://www.instagram.com/figueacero" target="_blank">
                <div class="icono iconos_svg">
                    <!-- <img src="assets/iconos/instagram.png" alt="instagram"> -->
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                        <path
                            d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                    </svg>
                </div>
            </a>
            <a href="https://www.linkedin.com/company/figueacero-s-a-de-c-v" target="_blank">
                <div class="icono iconos_svg">
                    <!-- <img src="assets/iconos/linked.png" alt="linkedIn"> -->
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                        <path
                            d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" />
                    </svg>
                </div>
            </a>
        </div>

    </mat-toolbar>

    <!-- Toolbar mobile -->
    <mat-toolbar class="toolbar-menu-responsive">
        <button mat-icon-button style="color: #ffffff;" (click)="toggleMenu()">
            <mat-icon>menu</mat-icon>
        </button>

        <div class="toolbar-menu__center">
            <!-- menu user -->
            <mat-icon class="toolbar-menu__img-icon iconoColor" [matMenuTriggerFor]="beforeMenu">
                perm_identity
            </mat-icon>

            <!-- float cart -->
            <div class="menuCart">
                <mat-icon class="toolbar-menu__img-icon iconoColor">
                    shopping_cart
                </mat-icon>

                <div class="menuCart__content">
                    <div class="menuCart__box-cart" #floatCart>

                        <ng-container #emptyCart *ngIf="productosCarrito.length === 0">
                            <div class="text-center">
                                <img src="assets/iconos/cart-empty.svg" alt="empty-cart" style="width: 100px;">
                                <br>
                                <small class="text-primary text-wrap">El carrito se encuentra vacío</small>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="productosCarrito.length > 0">
                            <!-- item row -->
                            <div class="menuCart__item" *ngFor="let itemCart of productosCarrito">
                                <!-- item imagen -->
                                <div class="menuCart__image">
                                    <img class="menuCart__img" [src]="itemCart.sUrlImagen">
                                </div>

                                <!-- item info -->
                                <div class="menuCart__info">
                                    <div class="menuCart__title">
                                        <span>{{ itemCart.sNombre }}</span>
                                    </div>
                                    <div class="menuCart__description">
                                        <span class="menuCart__price">{{ itemCart.dprecioCI | currency }}</span>
                                        <span class="menuCart__quantity mr-3">x{{ itemCart.dCantidad }}</span>
                                    </div>
                                </div>

                                <!-- item delete -->
                                <div class="menuCart__delete">
                                    <mat-icon color="primary" (click)="deleteItem(itemCart)">add_circle</mat-icon>
                                </div>
                            </div>

                            <!-- actions -->
                            <div class="menuCart__actions">
                                <button mat-raised-button color="primary" (click)="goToCart()">Ver carrito</button>
                                <!-- <button mat-raised-button color="primary" routerLink="/checkout">Pagar ahora</button> -->
                            </div>
                        </ng-container>


                    </div>
                </div>

            </div>

            <!-- float actions user -->
            <mat-menu #beforeMenu="matMenu" xPosition="before">
                <ng-container *ngIf="_userService.isLogged()">
                    <button mat-menu-item routerLink="/user/account">Mi cuenta</button>
                    <button mat-menu-item (click)="logout()">Cerrar sesión</button>
                </ng-container>

                <ng-container *ngIf="!_userService.isLogged()">
                    <button mat-menu-item (click)="openModal('signIn')">Iniciar sesión</button>
                    <button mat-menu-item (click)="openModal('signUp')">Registrarse</button>
                </ng-container>

            </mat-menu>
        </div>


        <div class="barraBuscador">
            <div class="select-form">
                <select class="" [formControl]="controlCategory">
                    <option selected [value]="0">Todos</option>
                    <option [value]="item.iIdCategoria" *ngFor="let item of categoriesList">{{ item.sNombreCategoria }}
                    </option>
                </select>
            </div>
            <input [formControl]="controlSearch" (keyup.enter)="search()">
            <button mat-button style="color: #ffffff;" (click)="search()"><span class="material-icons">
                    search
                </span></button>
        </div>

        <!-- <div class="toolbar-menu-responsive__actions">
            <img matRipple src="assets/images/svg/user.svg" [matMenuTriggerFor]="beforeMenu">
            <img matRipple src="assets/images/svg/cart-white.svg" [routerLink]="['/checkout']">

            <mat-menu #beforeMenu="matMenu" xPosition="before">
                <button mat-menu-item (click)="openModal('signIn')">Iniciar sesión</button>
                <button mat-menu-item (click)="openModal('signUp')">Registrarse</button>
            </mat-menu>
        </div> -->
    </mat-toolbar>
</header>