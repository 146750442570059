import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { pluck, catchError } from "rxjs/operators";
import { of, Observable } from "rxjs";
import {
  Product,
  Categoria,
  GenericResponse,
  ProductosDummy,
  ProductDetail,
} from "../models/products.model";

@Injectable({
  providedIn: "root",
})
export class ProductsService {
  urlLiquidacionNuevosProd = environment.urlAWS + "/";

  constructor(public http: HttpClient) {}

  productsList(): Observable<Product[]> {
    return this.http.get<Product[]>(environment.url + "productos").pipe(
      pluck("resultDto", "sDetalle"),
      catchError<Product[], Observable<Product[]>>((err) => of([]))
    );
  }

  getProductsByTipoAndByCategory(
    tipo: number,
    category: number,
    idxInicio: number,
    idxFinal: number
  ): Observable<GenericResponse<Product>> {
    return this.http.get<GenericResponse<Product>>(
      environment.url +
        `productos?iTipoProducto=${tipo}&iCategoria=${category}&indexInicio=${idxInicio}&indexFinal=${idxFinal}`
    );
  }

  getProductsByTipoAndByCategoryText(
    tipo: number,
    category: number,
    idxInicio: number,
    idxFinal: number,
    textCriterio: string
  ): Observable<GenericResponse<Product>> {
    const existCategory = category > 0 ? `&iCategoria=${category}` : "";
    console.log(category, existCategory);
    return this.http.get<GenericResponse<Product>>(
      environment.url +
        `buscaproductos?iTipoProducto=${tipo}${existCategory}&indexInicio=${idxInicio}&indexFinal=${idxFinal}&sCriterio=${textCriterio}`
    );
  }

  getProductById(id: number): Observable<ProductDetail[]> {
    return this.http
      .get<ProductDetail[]>(environment.url + `producto?idProducto=${id}`)
      .pipe(
        pluck("resultDto", "sDetalle"),
        catchError<ProductDetail[], Observable<ProductDetail[]>>((err) =>
          of([])
        )
      );
  }

  getCategorias(): Observable<Categoria[]> {
    return this.http.get<Categoria[]>(environment.url + "categorias").pipe(
      pluck("resultDto", "sDetalle"),
      catchError<Categoria[], Observable<Categoria[]>>((err) => of([]))
    );
  }

  getVariantes(idProducto: number): Observable<ProductDetail[]> {
    return this.http
      .get<ProductDetail[]>(
        environment.url + `variantesproducto?iIdProducto=${idProducto}`
      )
      .pipe(pluck("resultDto", "sDetalle"));
  }

  getProductosLiquidacion(
    viewname: string,
    filtro: string,
    page = 10
  ): Observable<any> {
    return this.http.get(
      `${
        this.urlLiquidacionNuevosProd
      }view?regxpag=${10}&pagina=${page}&viewname=${viewname}&filtro=${filtro}`
    );
  }
}
