import { Pipe, PipeTransform } from '@angular/core';
import { stringify } from 'querystring';
import { Order } from 'src/app/models/order.model';

@Pipe({
    name: 'filterPedido'
})
export class FilterPedidosPipe implements PipeTransform {

    transform(value: Order[], args: string): any {

        let pedidos = [];
        if (args == undefined) return value

        for (const pedido of value) {
            let [anio, month, day] = pedido.sFechaPedido.substring(0, 10).split('-');
            let fechaPedido = new Date(+anio, +month - 1, +day);
            
            [anio, month, day] = args.substring(0, 10).split('-');
            let fechaFiltro = new Date(+anio, +month, +day);
            if (fechaPedido > fechaFiltro)
                pedidos.push(pedido);
        }

        return pedidos;

    }

}
