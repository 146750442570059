// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // url: 'http://34.234.225.159:8080/RestFull_ECOMMERCE-1.0/rest/ECOMMERCE/',
  // url: 'https://figueacero.automatizate.mx/services/',
  url: "https://desa-figueacero.automatizate.mx/services/",
  urlAWS:
    "https://adtdnc9ob2.execute-api.us-east-2.amazonaws.com/Prod_PortalTiendaFigueacero",     
  secretKey: "laveronesasecretkeyonline",
  cognito: {
    userPoolId: "us-east-2_p6oPbcphZ",
    userPoolWebClientId: "3ffh6quh4vt910ipvibmu7ap86",
    identityPoolId: "us-east-2:064e023a-d18c-4dcf-a6dd-bd303d20ddef",
    region: "us-east-2",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
