import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StockSelectPipe } from "./pipes/stock-select.pipe";
import { FooterComponent } from "./@components/footer/footer.component";
import { ErrorValidatorPipe } from "./pipes/error-validator.pipe";
import { HeaderComponent } from "./@components/header/header.component";
import { MaterialModule } from "../material.module";
import { RouterModule } from "@angular/router";
import { ReactiveFormsModule } from "@angular/forms";
import { FilterPedidosPipe } from "./pipes/filter-pedidos.pipe";


const pipes = [StockSelectPipe, ErrorValidatorPipe, FilterPedidosPipe];
const components = [HeaderComponent, FooterComponent];
@NgModule({
  declarations: [...pipes, ...components],
  imports: [CommonModule, RouterModule, MaterialModule, ReactiveFormsModule],
  exports: [...pipes, ...components],
})
export class SharedModule {}
