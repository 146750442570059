import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { OrderResponse } from '../models/order.model';
import { Observable, of } from 'rxjs';
import { pluck, catchError } from 'rxjs/operators';
import { UserService } from './user.service';
import { Address, AddressAWS, CPAddress, RespAddress, RespDeleteAddress } from '../models/address.model';


@Injectable({
  providedIn: 'root'
})
export class AddressService {

  urlAws = environment.urlAWS;
  urlAdrresCP = "https://adtdnc9ob2.execute-api.us-east-2.amazonaws.com/Prod_PortalTiendaFigueacero/view?regxpag=10&pagina=1&viewname=view_clavecolonias"

  constructor(private http: HttpClient, private _userService: UserService) { }

  createAddress(address): Observable<OrderResponse> {
    const idUsuario = this._userService.getIdUser();
    const { sNombrePersonaRecibe, sCalle, sNumeroExterior, sNumeroInterior, sColonia, sDelegacion, sEstado, iCodigoPostal, sTelefono, sCompania } = address;
    return this.http.get(environment.url + `CreaDireccion?iIdUsuario=${idUsuario}&sCalle=${sCalle}&sNumeroExterior=${sNumeroExterior}&sNumeroInterior=${sNumeroInterior}&sColonia=${sColonia}&sDelegacion=${sDelegacion}&sEstado=${sEstado}&iCodigoPostal=${iCodigoPostal}&sNombrePersonaRecibe=${sNombrePersonaRecibe}&sTelefono=${sTelefono}&sCompania=${sCompania}`)
      .pipe(pluck('resultDto'))
  }

  createAddressAWS(address: AddressAWS) {
    const idUsuario = this._userService.getIdUser();
    address.iIdUsuario = idUsuario.toString();
    return this.http.post(environment.urlAWS + "/direcciones", address);
  }

  updateAddress(address: Address): Observable<OrderResponse> {
    const idUsuario = this._userService.getIdUser();
    const { sNombrePersonaRecibe, sCalle, sNumeroExterior, sNumeroInterior, sColonia, sDelegacion, sEstado, iCodigoPostal, iIdDireccion, sTelefono, sCompania } = address;
    return this.http.get(environment.url + `ModificaDireccion?iIdDireccion=${iIdDireccion}&iIdUsuario=${idUsuario}&sCalle=${sCalle}&sNumeroExterior=${sNumeroExterior}&sNumeroInterior=${sNumeroInterior}&sColonia=${sColonia}&sDelegacion=${sDelegacion}&sEstado=${sEstado}&iCodigoPostal=${iCodigoPostal}&sNombrePersonaRecibe=${sNombrePersonaRecibe}&sTelefono=${sTelefono}&sCompania=${sCompania}`)
      .pipe(pluck('resultDto'))
  }

  updateAddressAWS(address: AddressAWS) {    
    const idUsuario = this._userService.getIdUser();
    address.iIdUsuario = idUsuario.toString();
    return this.http.put(environment.urlAWS + "/direcciones",address);      
  }

  getAddress(): Observable<Address[]> {
    const idUsuario = this._userService.getIdUser();
    console.log({ idUsuario })
    return this.http.get<Address[]>(environment.url + `direccion?iIdUsuario=${idUsuario}`).pipe(
      pluck('resultDto', 'sDetalle'),
      catchError<any[], Observable<any[]>>((err) => of([]))
    )
  }

  getAddressAWS(): Observable<AddressAWS[]> {
    const iIdUsuario = this._userService.getIdUser();
    console.log({ iIdUsuario })
    return this.http.get<AddressAWS[]>(environment.urlAWS + "/direcciones?iIdUsuario=" + iIdUsuario);
  }

  getCp(cp: string): Observable<Address[]> {
    return this.http.get<Address[]>(environment.url + `cp?iCodigoPostal=${cp}`).pipe(
      pluck('resultDto', 'sDetalle'),
      catchError<any[], Observable<any[]>>((err) => of([]))
    )
  }

  getCPAws(cp: string): Observable<CPAddress[]> {
    return this.http.get<CPAddress[]>(this.urlAws + `/procesocompra/direcciones/cp?codigopostal=${cp}`).pipe(
      catchError<CPAddress[], Observable<CPAddress[]>>((err) => of([]))
    )
  }

  getAddressByID(idDireccion: string): Observable<AddressAWS[]> {
    return this.http.get<AddressAWS[]>(this.urlAws + `/procesocompra/direcciones/id?iIddireccion=${idDireccion}`).pipe(
      catchError<any[], Observable<any[]>>((err) => of([]))
    )
  }

  deleteAddress(req: { iIdUsuario: string, iIdDireccion: string }): Observable<RespDeleteAddress> {
    return this.http.delete<RespDeleteAddress>(this.urlAws + `/direcciones`, { body: req });
  }

  setIdAddress(id: string): void {
    localStorage.setItem("idAddress", id);
  }

  getIdAddress() {    
    return localStorage.getItem("idAddress");
  }

  getCP_AWS(cp: string) {
    return this.http.get(this.urlAdrresCP + "&filtro=" + cp.toString() );
  }

}
